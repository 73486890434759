import Flex from 'Components/Flex';
import React from 'react';
import styled from 'styled-components';
import { NBSP } from '../../constants';
import { Text, Title } from 'Components/Typography';
import infographicsM_0 from './img/infographics_0-m.svg';
import infographicsM_1 from './img/infographics_1-m.svg';
import infographicsPc from './img/infographics-pc.svg';
import Box from 'Components/Box';
import { EnumTextType } from 'theme';
import { EnumScreens, getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';

const items = [
  {
    title: `1. В момент работы за${NBSP}экраном`,
    text: 'Частота моргания глаз снижается',
    img: infographicsM_0,
  },
  {
    title: '2. К чему это приводит?',
    text: 'Наши глаза покрыты тонкой слезной пленкой. Eсли человек редко моргает:',
    img: infographicsM_1,
  },
  {
    title: `3. Разрывов становится так${NBSP}много,`,
    text: 'Что моргание уже не может восстановить целостность слезной пленки.',
  },
];

const StyledTitle = styled(Title).attrs({
  lvl: 3,
  textType: { sm: EnumTextType.h5, lg: EnumTextType.h3 },
})`
  ${mediaLgDown} {
    margin-bottom: ${getVwSm('4px')};
  }
`;

const ItemContainer = styled(Flex).attrs({ as: 'li', column: true, middle: true })`
  ${mediaLgUp} {
    max-width: ${getVwLg('570px')};
    text-align: center;
  }
`;

const ListItem = ({ title, text, img }: { title: string; text: string; img?: string }) => (
  <ItemContainer as="li">
    <Flex column px={{ sm: 15, lg: 0 }}>
      <StyledTitle>{title}</StyledTitle>
      <Text>{text}</Text>
    </Flex>

    {img && (
      <Box my={{ sm: 24 }} hideAfter={EnumScreens.lg}>
        <img src={img} alt="" />
      </Box>
    )}
  </ItemContainer>
);

const ListContainer = styled(Flex).attrs({
  column: true,
  between: true,
  wrap: true,
  px: { lg: 160 },
  pb: { sm: 24, lg: 0 },
  mt: { lg: 90 },
})`
  ${mediaLgUp} {
    flex-direction: row;
    height: ${getVwLg('665px')};
    background: center / ${getVwLg('1600px')} auto url('${infographicsPc}') no-repeat;

    & > :first-child {
      width: ${getVwLg('484px')};
    }

    & > :last-child {
      max-width: 100%;
      width: 100%;
      align-self: flex-end;
    }
  }
`;

const DryEyeList: React.FC = () => (
  <ListContainer>
    {items.map(item => (
      <ListItem key={item.title} {...item} />
    ))}
  </ListContainer>
);

export default DryEyeList;
