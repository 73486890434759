import React from 'react';
import Layout from 'Containers/Layout';
import Symptoms from 'Containers/Symptoms';
import Navbar from 'Components/Navbar';
import Footer from 'Components/Footer';
import { NavbarPages } from 'Components/Navbar';
import colors from 'theme/colors';

const SymptomsTheme = {
  bgColor: colors.paleRed,
  body: {
    color: colors.deepRed,
    highlight: colors.red,
  },
  button: {
    color: colors.paleRed,
    bgColor: colors.deepRed,
  },
  footer: {
    bgColor: colors.red,
    color: colors.paleRed,
  },
  disclaimer: {
    bgColor: colors.red,
    color: colors.paleRed,
  },
  ad: {
    borderColor: colors.red,
  },
};

const SymptomsPage: React.FC = () => (
  <Layout theme={SymptomsTheme}>
    <Navbar page={NavbarPages.SYMPTOMS} />
    <Symptoms />
    <Footer theme={SymptomsTheme.footer} />
  </Layout>
);

export default SymptomsPage;
