import React from 'react';
import screensPc from './img/screens-pc.png';
import screensM from './img/screens-m.svg';
import { Text, Highlight } from 'Components/Typography';
import colors from 'theme/colors';
import { EnumScreens, mediaLgUp } from 'theme/helpers/css';
import styled from 'styled-components';
import Box from 'Components/Box';
import useIsMobile from 'hooks/useIsMobile';

const TextContainer = styled(Box).attrs({
  bgColor: colors.paleRed90,
  px: 15,
  py: 15,
  mb: -182,
})`
  position: relative;

  ${mediaLgUp} {
    display: none;
  }
`;

const ScreenBlock = (): JSX.Element => {
  const isMobile = useIsMobile();

  return (
    <Box mt={{ lg: -454 }}>
      {isMobile ? (
        <>
          <TextContainer>
            <Text>
              Из-за долгой непрерывной работы глаз вблизи возникает перенапряжение{' '}
              <Highlight>цилиарной мышцы,</Highlight> она теряет способность сокращаться и расслабляться нормальным
              образом. Это приводит к зрительным нарушениям.
            </Text>
          </TextContainer>
          <Box>
            <img src={screensM} width="100%" height="auto" />
          </Box>
        </>
      ) : (
        <Box hideBefore={EnumScreens.sm}>
          <img src={screensPc} width="100%" height="auto" />
        </Box>
      )}
    </Box>
  );
};

export default ScreenBlock;
