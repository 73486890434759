import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './Icon';

const IconBubble: React.FC<{ theme?: any }> = ({ theme = {}, ...props }) => (
  <Icon viewBox="0 0 167 170" fill="none" {...props}>
    <path
      d="M75.5443 16.8512C35.1041 17.5773 1.79047 49.8717 0.0713094 90.083C-0.588367 105.519 3.3597 119.99 10.6361 132.274L0.481109 162.619C-0.948191 166.895 3.12981 170.953 7.4277 169.531L37.9228 159.416C50.2667 166.667 64.8096 170.585 80.322 169.929C120.732 168.218 153.186 135.068 153.916 94.8272C154.706 51.4232 119.173 16.0655 75.5443 16.8512Z"
      fill={theme.iconColor || theme.body?.color}
    />
    <path
      d="M86.9896 3.11605C46.5495 3.8421 13.2358 36.1365 11.5166 76.3478C10.8569 91.7839 14.805 106.255 22.0814 118.538L13.6056 146.884C12.716 149.858 15.3347 152.693 18.3933 152.066L49.3581 145.681C61.702 152.931 76.2449 156.85 91.7573 156.194C132.167 154.483 164.622 121.333 165.351 81.092C166.141 37.6881 130.618 2.34026 86.9896 3.11605Z"
      fill="#FFF8F5"
    />
    <path
      d="M9.03746 160.162C12.046 152.036 14.4648 143.701 16.8836 135.387C18.2629 130.643 19.6423 125.888 21.0216 121.144C22.4909 116.072 18.6328 112.093 16.8236 107.329C2.0209 68.1523 22.4509 23.4056 62.3113 9.61064C101.432 -3.92575 143.382 17.7862 157.455 55.9487C170.868 92.2911 148.419 136.391 112.157 148.853C101.942 152.364 91.0273 153.628 80.2626 152.762C74.6553 152.315 69.058 151.221 63.7007 149.5C61.012 148.645 58.3933 147.59 55.8445 146.407C54.1054 145.591 52.1163 144.02 50.1673 143.95C46.0493 143.811 40.8219 146.317 36.8838 147.352C32.416 148.525 27.9782 149.818 23.5104 151.012C19.7722 152.006 21.5113 157.735 25.2495 156.741C29.5574 155.597 33.9053 154.552 38.2132 153.399C40.9918 152.653 43.7704 151.897 46.5491 151.151C47.5686 150.873 48.5981 150.534 49.6276 150.316C51.6166 149.908 50.3672 150.485 50.1273 150.375C68.5983 158.63 88.1387 161.346 107.949 156.174C146.87 146.009 172.727 106.474 165.911 66.8892C162.602 47.6936 153.237 31.2828 138.274 18.7509C122.252 5.314 101.372 -1.5089 80.4425 0.281368C39.3826 3.79228 6.6986 38.8119 6.48871 79.7592C6.42874 91.4357 8.77758 103.023 13.6052 113.675C13.9051 114.331 16.4738 119.264 16.3139 117.782C16.3639 118.2 15.9441 118.996 15.8441 119.394C15.2544 121.681 14.5248 123.929 13.8651 126.197C10.6866 137.107 7.29831 148.018 4.91948 159.138C4.31977 161.863 8.10791 162.668 9.03746 160.162Z"
      fill={theme.iconColor || theme.body?.color}
    />
    <path
      d="M128.389 66.4315H101.732V39.9058H75.0747V66.4315H48.4277V92.9473H75.0747V119.473H101.732V92.9473H128.389V66.4315Z"
      fill="#E58C83"
    />
    <path
      d="M77.9031 119.951C78.143 111.377 77.6732 102.764 77.4633 94.181C77.3834 90.9784 72.3858 91.0978 72.4658 94.3003C72.6857 102.874 72.6457 111.497 73.3154 120.06C73.5453 122.984 77.8232 122.885 77.9031 119.951Z"
      fill="#934134"
    />
    <path
      d="M46.4891 65.9643C46.3292 74.9753 46.4291 84.0062 46.749 93.0172C46.7989 94.3698 47.9384 95.4639 49.3077 95.444C57.4837 95.3346 65.6697 95.2252 73.8457 95.1158C77.0641 95.076 76.9442 90.103 73.7257 90.1428C65.5497 90.2522 57.3638 90.3616 49.1878 90.471C50.0373 91.2766 50.8969 92.0922 51.7465 92.8978C51.4267 83.8868 51.6865 74.846 50.8869 65.8648C50.637 63.0601 46.5391 63.1297 46.4891 65.9643Z"
      fill="#934134"
    />
    <path
      d="M53.036 68.2219C60.3324 69.1071 67.7987 68.8684 75.1451 68.938C76.5445 68.948 77.5939 67.6849 77.6039 66.3621C77.6939 57.351 78.2836 48.35 78.3036 39.339C77.414 40.264 76.5345 41.1889 75.6449 42.1238C84.3106 41.905 92.9764 41.6763 101.642 41.4376C100.753 40.6021 99.873 39.7567 98.9835 38.9213C99.1234 48.1113 99.1534 57.3013 99.2333 66.4814C99.2433 67.8838 100.463 68.8684 101.792 68.9082C108.659 69.1171 115.515 69.3359 122.382 69.5447C125.6 69.6442 125.47 64.6712 122.262 64.5718C115.395 64.3629 108.539 64.1441 101.672 63.9352C102.522 64.7409 103.381 65.5564 104.231 66.3621C104.161 57.172 104.181 47.982 104.171 38.792C104.171 37.3896 102.892 36.2558 101.512 36.2756C92.8465 36.4049 84.1707 36.5442 75.505 36.6934C74.0057 36.7232 72.8363 38.0162 72.8463 39.4782C72.8762 48.4793 72.4565 57.4803 72.5564 66.4913C73.376 65.636 74.1956 64.7707 75.0152 63.9154C67.8587 63.985 60.5523 63.7164 53.4358 64.5817C51.5467 64.8105 50.7871 67.9534 53.036 68.2219Z"
      fill="#934134"
    />
    <path
      d="M126.721 65.0991C125.491 74.2593 125.781 83.7875 125.871 93.0173C126.691 92.162 127.5 91.3166 128.32 90.4612C119.434 90.4413 110.549 90.4214 101.663 90.4015C100.234 90.4015 99.1741 91.6647 99.1642 93.0173C99.0542 101.73 99.2441 110.443 99.0742 119.145C99.8938 118.29 100.703 117.444 101.523 116.589C93.9467 116.559 86.3704 116.609 78.7941 116.619C75.5757 116.619 75.6957 121.592 78.9141 121.592C86.4904 121.582 94.0667 121.622 101.643 121.582C103.032 121.572 104.072 120.359 104.092 119.026C104.182 110.313 104.152 101.601 104.292 92.888C103.462 93.7633 102.622 94.6286 101.793 95.5038C110.679 95.4839 119.564 95.464 128.45 95.4441C129.819 95.4441 130.919 94.2108 130.899 92.888C130.759 83.8571 131.788 74.4383 130.359 65.487C130.069 63.6868 127.03 62.8315 126.721 65.0991Z"
      fill="#934134"
    />
  </Icon>
);

export default withTheme(IconBubble);
