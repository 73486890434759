import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';
import { Text, Title } from 'Components/Typography';
import { EnumFontFamily, EnumTextType } from 'theme';
import Flex from 'Components/Flex';
import Box from 'Components/Box';

const Container = styled(Flex).attrs(props => ({
  column: true,
  py: { sm: 10, lg: 0 },
  px: { sm: 8, lg: 0 },
  bgColor: colors.paleRed,
  ...props,
}))`
  border-radius: ${getVwSm('16px')};
  z-index: 2;

  ${mediaLgDown} {
    border: ${getVwSm('2px')} solid ${colors.red};
  }

  ${mediaLgUp} {
    text-align: center;
    align-items: center;
    max-width: ${getVwLg('385px')};
    border-radius: ${getVwLg('16px')};
    background-color: ${colors.paleRed90};
  }
`;

const TitleContainer = styled(Flex).attrs({ middle: true })`
  ${mediaLgUp} {
    flex-direction: column;
  }
`;

const Icon = styled.img`
  width: ${getVwSm('54px')};
  height: auto;

  ${mediaLgUp} {
    width: ${getVwLg('108px')};
  }
`;

const StyledTitle = styled(Title).attrs({
  lvl: 2,
  fontFamily: { sm: EnumFontFamily.fewriter, lg: EnumFontFamily.introscript },
  textType: { sm: EnumTextType.bodyL, lg: EnumTextType.h3 },
})`
  ${mediaLgDown} {
    margin-left: ${getVwSm('4px')};
  }
`;

interface IProblemCard {
  title: string;
  text: string;
  iconUrl: string;
  className?: string;
}

const ProblemCard: React.FC<IProblemCard> = ({ title, text, iconUrl, ...props }) => (
  <Container {...props}>
    <TitleContainer>
      <Icon src={iconUrl} />

      <StyledTitle>{title}</StyledTitle>
    </TitleContainer>

    <Box pb={{ sm: 10, lg: 0 }}>
      <Text fontFamily={{ sm: EnumFontFamily.futura, lg: EnumFontFamily.fewriter }}>{text}</Text>
    </Box>
  </Container>
);

export const StickyCard = styled(ProblemCard).attrs({
  ml: { sm: 15, lg: 136 },
  my: { sm: 24 },
  py: { sm: 10, lg: 24 },
  px: { sm: 8, lg: 24 },
})`
  position: sticky;
  top: ${getVwSm('69px')};
  width: max-content;

  ${mediaLgDown} {
    & > :last-child {
      display: none;
    }
  }

  ${mediaLgUp} {
    max-width: ${getVwLg('590px')};
    top: 7vh;
    align-items: flex-start;
    text-align: left;

    ${Icon} {
      display: none;
    }
  }
`;

export default ProblemCard;
