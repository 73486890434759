import React, { useContext } from 'react';
import Flex from 'Components/Flex';
import styled, { ThemeProvider } from 'styled-components';
import { getVwLg, getVwSm, mediaLgUp } from 'theme/helpers/css';
import IconBubble from 'Components/Icons/IconBubble';
import { ThemeContext } from 'styled-components';

const StyledBubble = styled(IconBubble)`
  width: ${getVwSm('64px')};
  height: auto;

  ${mediaLgUp} {
    width: ${getVwLg('166px')};
  }
`;

const Container = styled(Flex).attrs({
  column: true,
  middle: true,
  px: { sm: 15, lg: 38 },
  pt: { sm: 26, lg: 12 },
  pb: { sm: 16, lg: 32 },
})`
  position: relative;
  max-width: ${getVwSm('345px')};
  border: ${getVwSm('2px')} solid ${props => props.theme.ad?.borderColor || props.theme.body?.color};
  border-radius: ${getVwSm('20px')} ${getVwSm('20px')} ${getVwSm('20px')} ${getVwSm('5px')};
  background-color: ${props => props.theme.ad?.bgColor};

  ${StyledBubble} {
    position: absolute;
    left: ${getVwSm('-12px')};
    top: ${getVwSm('-8px')};
  }

  ${mediaLgUp} {
    max-width: ${getVwLg('554px')};
    border-width: ${getVwLg('5px')};
    border-radius: ${getVwLg('32px')} ${getVwLg('32px')} ${getVwLg('32px')} ${getVwLg('12px')};

    ${StyledBubble} {
      position: absolute;
      left: ${getVwLg('-68px')};
      top: ${getVwLg('-76px')};
    }
  }
`;

const AdContainer: React.FC = ({ children }) => {
  const theme = useContext(ThemeContext);
  const adTheme = {
    ...theme,
    iconColor: theme.ad?.borderColor,
    button: {
      ...theme.button,
      bgColor: theme.ad?.borderColor || theme.button?.bgColor,
    },
  };

  return (
    <ThemeProvider theme={adTheme}>
      <Container>
        <StyledBubble />
        {children}
      </Container>
    </ThemeProvider>
  );
};

export default AdContainer;
