import React from 'react';
import Flex from 'Components/Flex';
import Button from 'Components/Button';
import styled from 'styled-components';
import { Title, Text } from 'Components/Typography';
import { EnumTextType } from 'theme';
import { getVwLg, getVwSm, mediaLgUp } from 'theme/helpers/css';
import { testTheme } from 'Containers/Test';
import { URLs } from '../../constants';

const Container = styled(Flex).attrs({
  column: true,
  middle: true,
  center: true,
  px: { sm: 15, lg: 717 },
})`
  height: ${getVwSm('324px')};

  ${mediaLgUp} {
    height: ${getVwLg('800px')};
  }
`;

const StyledTitle = styled(Title).attrs({
  lvl: 2,
  textType: { sm: EnumTextType.h4, lg: EnumTextType.h3 },
  align: 'center',
})`
  margin-bottom: ${getVwSm('16px')};

  ${mediaLgUp} {
    margin-bottom: ${getVwLg('16px')};
    max-width: ${getVwLg('486px')};
  }
`;

const TestBlock = (): JSX.Element => (
  <Container>
    <StyledTitle>Какая вероятность, что&nbsp;у&nbsp;меня есть риск развития&nbsp;КЗС?</StyledTitle>
    <Text align="center">Мы разработали быстрый тест, который&nbsp;поможет вам оценить нагрузку&nbsp;ваших глаз!</Text>
    <Button to={URLs.test} theme={testTheme} mt={{ sm: 16, lg: 32 }}>
      Пройти тест
    </Button>
  </Container>
);

export default TestBlock;
