import { EnumScreens } from 'theme/helpers/css';
import alienM from './json/alien-m.json';
import alienPc from './json/alien-pc.json';
import burningM from './json/burning-m.json';
import burningPc from './json/burning-pc.json';
import defaultM from './json/default-m.json';
import defaultPc from './json/default-pc.json';
import discomfortM from './json/discomfort-m.json';
import discomfortPc from './json/discomfort-pc.json';
import dryM from './json/dry-m.json';
import dryPc from './json/dry-pc.json';
import painsM from './json/pains-m.json';
import painsPc from './json/pains-pc.json';
import redEyesM from './json/red_eyes-m.json';
import redEyesPc from './json/red_eyes-pc.json';
import wetEyesM from './json/wet_eyes-m.json';
import wetEyesPc from './json/wet_eyes-pc.json';
import withdrawnM from './json/withdrawn-m.json';
import withdrawnPc from './json/withdrawn-pc.json';
import flicker from './json/flicker.json';
import flies from './json/flies.json';
import headache from './json/headache.json';
import slowFocus from './json/slow_focus.json';
import doubling from './json/doubling.json';
import mist from './json/mist.json';
import screen from './json/screen.json';

export enum EnumDryEyesAnimations {
  ALIEN,
  BURNING,
  DEFAULT,
  DISCOMFORT,
  DRY,
  PAINS,
  RED_EYES,
  WET_EYES,
  WITHDRAWN,
}

export const DryEyesAnimationNames = {
  [EnumDryEyesAnimations.DISCOMFORT]: 'дискомфорт в глазах',
  [EnumDryEyesAnimations.WITHDRAWN]: 'усталость глаз',
  [EnumDryEyesAnimations.DRY]: 'сухость глаз',
  [EnumDryEyesAnimations.BURNING]: 'жжение в глазах',
  [EnumDryEyesAnimations.PAINS]: 'боль или резь в глазах',
  [EnumDryEyesAnimations.RED_EYES]: 'покраснение глаз',
  [EnumDryEyesAnimations.ALIEN]: 'чувство инородного тела',
  [EnumDryEyesAnimations.WET_EYES]: 'периодическое слезотечение',
};

export const dryEyesAnimations = {
  [EnumScreens.sm]: {
    [EnumDryEyesAnimations.ALIEN]: alienM,
    [EnumDryEyesAnimations.BURNING]: burningM,
    [EnumDryEyesAnimations.DEFAULT]: defaultM,
    [EnumDryEyesAnimations.DISCOMFORT]: discomfortM,
    [EnumDryEyesAnimations.DRY]: dryM,
    [EnumDryEyesAnimations.PAINS]: painsM,
    [EnumDryEyesAnimations.RED_EYES]: redEyesM,
    [EnumDryEyesAnimations.WET_EYES]: wetEyesM,
    [EnumDryEyesAnimations.WITHDRAWN]: withdrawnM,
  },
  [EnumScreens.lg]: {
    [EnumDryEyesAnimations.ALIEN]: alienPc,
    [EnumDryEyesAnimations.BURNING]: burningPc,
    [EnumDryEyesAnimations.DEFAULT]: defaultPc,
    [EnumDryEyesAnimations.DISCOMFORT]: discomfortPc,
    [EnumDryEyesAnimations.DRY]: dryPc,
    [EnumDryEyesAnimations.PAINS]: painsPc,
    [EnumDryEyesAnimations.RED_EYES]: redEyesPc,
    [EnumDryEyesAnimations.WET_EYES]: wetEyesPc,
    [EnumDryEyesAnimations.WITHDRAWN]: withdrawnPc,
  },
};

export enum EnumAsthenopiaAnimations {
  FLICKER,
  FLIES,
  HEADACHE,
  SLOW_FOCUS,
  DOUBLING,
  MIST,
  DEFAULT,
}

export const asthenopiaAnimationNames = {
  [EnumAsthenopiaAnimations.FLICKER]: 'дрожание видимого объекта',
  [EnumAsthenopiaAnimations.SLOW_FOCUS]: 'замедленная перефокусировка зрения',
  [EnumAsthenopiaAnimations.DOUBLING]: 'двоение',
  [EnumAsthenopiaAnimations.MIST]: 'затуманивание зрения',
  [EnumAsthenopiaAnimations.FLIES]: '“мушки” в глазах',
  [EnumAsthenopiaAnimations.HEADACHE]: 'головная боль',
};

export const asthenopiaAnimations = {
  [EnumScreens.lg]: {
    [EnumAsthenopiaAnimations.FLICKER]: flicker,
    [EnumAsthenopiaAnimations.FLIES]: flies,
    [EnumAsthenopiaAnimations.HEADACHE]: headache,
    [EnumAsthenopiaAnimations.SLOW_FOCUS]: slowFocus,
    [EnumAsthenopiaAnimations.DOUBLING]: doubling,
    [EnumAsthenopiaAnimations.MIST]: mist,
    [EnumAsthenopiaAnimations.DEFAULT]: screen,
  },
};

asthenopiaAnimations[EnumScreens.sm] = asthenopiaAnimations[EnumScreens.lg];
