import React, { ReactNode } from 'react';
import Flex from 'Components/Flex';
import useAnimation from 'hooks/useAnimationNew';
import useIsMobile from 'hooks/useIsMobile';
import styled from 'styled-components';
import { EnumScreens, getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';
import { ToggleButton } from 'Components/Button';
import { useEffect } from 'react';
import { useState } from 'react';
import { Title } from 'Components/Typography';
import { EnumTextType } from 'theme';
import Box from 'Components/Box';

const Container = styled(Flex).attrs({
  column: true,
  center: true,
  px: { lg: 160 },
  pt: { lg: 90 },
})`
  ${mediaLgDown} {
    overflow-x: hidden;

    ${Title} {
      text-decoration: underline;
    }
  }

  ${mediaLgUp} {
    position: relative;
    min-height: ${getVwLg('1080px')};
  }
`;

const AnimationContainer = styled(Flex).attrs({
  center: true,
  middle: true,
})`
  z-index: 0;

  ${mediaLgUp} {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    svg {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

const ButtonsContainer = styled(Flex).attrs({
  px: { sm: 8, lg: 0 },
  py: { sm: 8, lg: 0 },
})<{ children?: ReactNode[] }>`
  z-index: 1;

  ${mediaLgDown} {
    width: 100vw;
    overflow: scroll;

    ${ToggleButton} {
      flex-shrink: 0;
      margin: 0 ${getVwSm('8px')};
    }
  }

  ${mediaLgUp} {
    display: grid;
    grid-auto-flow: column;
    grid-template: repeat(${props => Math.ceil(props.children?.length / 2)}, max-content) / repeat(2, 1fr);
    grid-row-gap: ${getVwLg('36px')};

    & > :nth-last-child(-n + ${props => Math.floor(props.children?.length / 2)}) {
      justify-self: flex-end;
    }
  }
`;

const TitleContainer = styled(Box).attrs({
  pl: { sm: 15, lg: 0 },
  mb: { sm: 16, lg: 96 },
  mt: { lg: -124 },
})`
  z-index: 1;
`;

interface IAnimationBlock {
  animationsDict: { [k: string]: any };
  animationNamesDict: { [k: number]: string };
  segments: [[number, number], [number, number], [number, number]];
  defaultKey: number;
  speed?: number;
  className?: string;
}

const DryEyeAnimation: React.FC<IAnimationBlock> = ({
  animationsDict,
  animationNamesDict,
  segments,
  speed,
  defaultKey,
  className,
}) => {
  const isMobile = useIsMobile();
  const animations = animationsDict[isMobile ? EnumScreens.sm : EnumScreens.lg];
  const [currentAnim, setCurrentAnim] = useState(defaultKey);
  const [queued, setQueued] = useState();

  const [container, animInstance] = useAnimation({
    animationData: animations[currentAnim],
    loop: true,
  });

  const switchAnim = key => {
    setQueued(key);
    animInstance.loop = false;
    animInstance.playSegments(segments[2], false);
    animInstance.addEventListener('complete', () => setCurrentAnim(key));
  };

  useEffect(() => {
    if (animInstance?.isLoaded) {
      speed && animInstance.setSpeed(speed);
      animInstance.playSegments([segments[0], segments[1]], true);
    }
  }, [animInstance, speed]);

  return (
    <Container className={className}>
      <TitleContainer>
        <Title textType={EnumTextType.h3}>Симптомы:</Title>
      </TitleContainer>

      <AnimationContainer ref={container} />

      <ButtonsContainer>
        {Object.entries(animationNamesDict).map(([k, name]) => (
          <ToggleButton key={k} onClick={() => switchAnim(k)} active={k === queued}>
            {name}
          </ToggleButton>
        ))}
      </ButtonsContainer>
    </Container>
  );
};

export default DryEyeAnimation;
