import React from 'react';
import styled from 'styled-components';
import { getVwLg, getVwSm, mediaLgUp } from 'theme/helpers/css';
import { Text, Title } from 'Components/Typography';
import Flex from 'Components/Flex';
import Box from 'Components/Flex';
import { EnumScreens } from 'theme/helpers/css';
import { EnumFontFamily, EnumTextType } from 'theme';
import ProblemCard from './ProblemCard';
import { NBSP } from '../../constants';
import intro from './img/intro.svg';
import iconDryEye from './img/icon_dry_eye.svg';
import iconAsthenopia from './img/icon_asthenopia.svg';
import Disclaimer, { EnumDisclaimers, DisclaimerWrapper } from 'Components/Disclaimer';

export const dryEyeSyndrome = {
  title: `Синдром сухого${NBSP}глаза`,
  text: 'Нарушения, вызванные дефицитом увлажнения глаз.',
  iconUrl: iconDryEye,
};

export const asthenopia = {
  title: 'Астенопия',
  text: 'Зрительные нарушения, вызванные неравномерным сокращением глазной мышцы.',
  iconUrl: iconAsthenopia,
};

const Container = styled(Flex).attrs({
  column: true,
  between: true,
  middle: true,
  px: { sm: 15, lg: 160 },
  pb: { sm: 128, lg: 0 },
})`
  position: relative;

  ${mediaLgUp} {
    height: 100vh;
    min-height: ${getVwLg('800px')};
    flex-direction: row;
  }
`;

const IntroContainer = styled(Flex).attrs({
  column: true,
  mb: { sm: 18, lg: 0 },
  hideAfter: EnumScreens.lg,
})``;

export const IntroTitle = styled(Title).attrs({
  lvl: 1,
  textType: EnumTextType.h5,
})`
  margin-bottom: ${getVwSm('4px')};
`;

const IntroIllustration = styled.img.attrs({
  src: intro,
})`
  width: ${getVwLg('840px')};
  height: auto;
`;

const AboutBlock: React.FC = () => (
  <Container>
    <IntroContainer>
      <IntroTitle>КЗС – симптомокомплекс двух проблем,</IntroTitle>
      <Text>
        Которые развиваются одновременно и влияют друг на друга, усиливая негативное воздействие каждой из них.
      </Text>
    </IntroContainer>

    <ProblemCard {...dryEyeSyndrome} />

    <Flex center hideAfter={EnumScreens.lg}>
      <Text fontFamily={EnumFontFamily.introscript} textType={EnumTextType.h3}>
        +
      </Text>
    </Flex>

    <Box hideBefore={EnumScreens.sm}>
      <IntroIllustration />
    </Box>

    <ProblemCard {...asthenopia} />

    <DisclaimerWrapper>
      <Disclaimer type={EnumDisclaimers.SEE_DOCTOR} />
    </DisclaimerWrapper>
  </Container>
);

export default AboutBlock;
