import React from 'react';
import colors from 'theme/colors';
import AnimationBlock from './AnimationBlock';
import AboutBlock, { asthenopia, dryEyeSyndrome } from './AboutBlock';
import DryEyeList from './DryEyeList';
import {
  asthenopiaAnimationNames,
  asthenopiaAnimations,
  DryEyesAnimationNames,
  dryEyesAnimations,
  EnumAsthenopiaAnimations,
  EnumDryEyesAnimations,
} from './animationData';
import { StickyCard } from './ProblemCard';
import ListWithIcons from './ListWithIcons';
import Box from 'Components/Box';
import Flex from 'Components/Flex';
import TestBlock from './TestBlock';
import ScreenBlock from './ScreenBlock';

import iconShield from './img/icon_shield.svg';
import iconEye from './img/icon_eye.svg';
import iconMovement from './img/icon_movement.svg';
import iconMinerals from './img/icon_minerals.svg';
import iconPerfomance from './img/icon_perfomance.svg';
import iconBlindness from './img/icon_blindness.svg';
import iconReading from './img/icon_reading.svg';
import { getVwLg, getVwSm, mediaLgDown, mediaLgUp } from 'theme/helpers/css';
import styled from 'styled-components';
import AdContainer from 'Components/AdContainer';
import { Title, Text, HandText } from 'Components/Typography';
import Button from 'Components/Button';
import { EnumTextType } from 'theme';
import { URLs } from '../../constants';
import { sentissUrl } from 'Components/Footer/constants';
import product from './img/product.svg';
import Disclaimer, { EnumDisclaimers, DisclaimerWrapper } from 'Components/Disclaimer';
import Logo from 'Components/Logo';

const dryEyeList = [
  {
    text: 'Снижение защиты глаз \nот патогенных микробов',
    iconUrl: iconShield,
  },
  {
    text: 'Повышение риска развития \nконъюнктивита и других инфекций',
    iconUrl: iconEye,
  },
  {
    text: 'Дефицит смазки для движения \nглазного яблока',
    iconUrl: iconMovement,
  },
  {
    text: 'Нехватка веществ \nдля питания роговицы',
    iconUrl: iconMinerals,
  },
];

const asthenopiaList = [
  {
    text: 'Снижение зрительной \nработоспособности',
    iconUrl: iconPerfomance,
  },
  {
    text: 'Развитие или прогрессирование \nблизорукости (миопии)',
    iconUrl: iconBlindness,
  },
  {
    text: 'Возникновение пресбиопии \nили неспособности рассмотреть маленькие предметы \nили текст на близком расстоянии',
    iconUrl: iconReading,
  },
];

const StyledAnimationBlock = styled(AnimationBlock)`
  ${mediaLgDown} {
    svg {
      transform: translate3d(0px, 0px, 0px) scale(1.4) !important;
    }
  }
`;

const ProductPlacement = styled.img.attrs({ src: product })`
  width: ${getVwSm('96px')};
  height: auto;

  ${mediaLgUp} {
    width: ${getVwLg('156px')};
  }
`;

const NegativeEffectsBlock = styled(Flex).attrs({
  pt: { sm: 12, lg: 230 },
  pb: { sm: 116, lg: 200 },
  px: { sm: 15, lg: 160 },
  between: true,
})`
  position: relative;

  ${mediaLgDown} {
    flex-direction: column;
  }
`;

const Container = styled(Box).attrs({
  bgColor: colors.paleRed,
  pt: { sm: 140, lg: 0 },
})``;

const Symptoms: React.FC = () => (
  <Container bgColor={colors.paleRed} pt={{ sm: 140, lg: 0 }}>
    <Logo />
    <AboutBlock />

    <div>
      <StickyCard {...dryEyeSyndrome} />
      <DryEyeList />
      <AnimationBlock
        animationsDict={dryEyesAnimations}
        animationNamesDict={DryEyesAnimationNames}
        segments={[
          [0, 7],
          [7, 27],
          [27, 30],
        ]}
        defaultKey={EnumDryEyesAnimations.DEFAULT}
      />
      <NegativeEffectsBlock>
        <ListWithIcons items={dryEyeList} />

        <Box mt={{ sm: 28, lg: 0 }}>
          <AdContainer>
            <Title textType={EnumTextType.h3}>Профилактика</Title>

            <Box my={{ sm: 8, lg: 12 }}>
              <Text>
                Чтобы обеспечить защиту глазам от синдрома сухога глаза в момент зрительной нагрузки, используйте
                увлажняющие капли для глаз.
              </Text>
            </Box>

            <Flex middle mb={{ sm: 16, lg: 20 }}>
              <Box mr={{ sm: 8, lg: 18 }}>
                <ProductPlacement />
              </Box>
              <HandText>Капли Офтолик©¹ способствуют увлажнению и поддерживают работу&nbsp;глаз.</HandText>
            </Flex>

            <Button href={sentissUrl}>Перейти на сайт²</Button>

            <Box mt={{ sm: 16, lg: 24 }} ml={{ sm: -16 }}>
              <Text textType={EnumTextType.note}>
                ¹Регистрационный номер препарата: ЛСР-001359/08 <br />
                ²Переход на сайт Oftolik
              </Text>
            </Box>
          </AdContainer>
        </Box>
        <DisclaimerWrapper>
          <Disclaimer type={EnumDisclaimers.BEFORE_USING} />
        </DisclaimerWrapper>
      </NegativeEffectsBlock>
    </div>

    <div>
      <StickyCard
        {...{
          ...asthenopia,
          text: 'быстро наступающее утомление глаз \nво время зрительной работы, \nособенно при работе на близком расстоянии',
        }}
      />
      <ScreenBlock />
      <StyledAnimationBlock
        animationsDict={asthenopiaAnimations}
        animationNamesDict={asthenopiaAnimationNames}
        segments={[
          [0, 10],
          [10, 70],
          [70, 80],
        ]}
        defaultKey={EnumAsthenopiaAnimations.FLICKER}
      />

      <NegativeEffectsBlock>
        <ListWithIcons items={asthenopiaList} />

        <Box mt={{ sm: 28, lg: 0 }}>
          <AdContainer>
            <Title textType={EnumTextType.h3}>Профилактика</Title>

            <Box mt={{ sm: 8, lg: 12 }} mb={{ sm: 16, lg: 20 }}>
              <Text>
                Для восстановления зрительной силы глаз есть специальные капли, которые расслабляют перенапряженные
                части цилиарной мышцы и приводят в тонус ослабленную часть.
                <br />
                <br />
                Посоветуйтесь с врачом-офтальмологом, как устроить перезагрузку своим глазам!
              </Text>
            </Box>

            <Button href={URLs.makeAppointment}>Записаться на приём</Button>
          </AdContainer>
        </Box>
        <DisclaimerWrapper>
          <Disclaimer type={EnumDisclaimers.NOT_DOCTOR} />
        </DisclaimerWrapper>
      </NegativeEffectsBlock>
    </div>

    <TestBlock />
  </Container>
);

export default Symptoms;
