import React from 'react';
import styled from 'styled-components';
import Flex from 'Components/Flex';
import { HandText, Title } from 'Components/Typography';
import { EnumTextType } from 'theme';
import { mediaLgUp, getVwLg, getVwSm } from 'theme/helpers/css';

interface IListItem {
  iconUrl: string;
  text: string;
}

const ItemContainer = styled(Flex).attrs({ as: 'li', middle: true })`
  ${HandText} {
    white-space: pre-line;
    margin-left: ${getVwSm('8px')};
  }

  ${mediaLgUp} {
    max-width: ${getVwLg('700px')};

    ${HandText} {
      margin-left: ${getVwLg('12px')};
    }
  }
`;

const Icon = styled.img`
  width: ${getVwSm('32px')};
  height: ${getVwSm('32px')};
  flex-shrink: 0;

  ${mediaLgUp} {
    width: ${getVwLg('64px')};
    height: ${getVwLg('64px')};
  }
`;

export const ListItem: React.FC<IListItem> = ({ iconUrl, text }) => (
  <ItemContainer>
    <Icon src={iconUrl} />
    <HandText>{text}</HandText>
  </ItemContainer>
);

export const List = styled.ul`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: ${getVwSm('12px')};

  ${mediaLgUp} {
    grid-row-gap: ${getVwLg('32px')};
  }
`;

const StyledTitle = styled(Title).attrs({
  lvl: 3,
  textType: EnumTextType.h3,
})`
  margin-bottom: ${getVwSm('12px')};

  ${mediaLgUp} {
    margin-bottom: ${getVwLg('48px')};
  }
`;

const ListWithIcons: React.FC<{ items: IListItem[] }> = ({ items }) => (
  <Flex column>
    <StyledTitle>Последствия:</StyledTitle>
    <List>
      {items.map(item => (
        <ListItem key={item.text} {...item} />
      ))}
    </List>
  </Flex>
);

export default ListWithIcons;
